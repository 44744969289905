.headerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  background-color: #fffdfa;
}

.header_content {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 2rem;
  color: #3f2203;
}

.header_title {
  line-height: 1.2;
  font-size: 64px;
  width: 80%;
  font-weight: bold;
}

.header_subtitle {
  font-size: 24px;
  font-weight: 400;
  width: 80%;
}

.inforContainer {
  display: flex;
  padding: 4rem;
  background-color: #fff;
  color: #3f2203;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  box-shadow: 0px 0px 5px 0px #00000040;
}

.infotContainer_heading {
  font-size: 24px;
  font-weight: 600;
}

.inforContainer_content {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  justify-content: start;
  width: 100%;
}

.infocontent {
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 1rem;
  font-weight: 700;
  font-size: 16px;
  width: 45%;
  text-align: left;
}

.quiz_cards {
  display: flex;
  padding: 3rem;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  gap: 2rem;
  background-color: #fffdfa;
}

.quiz_card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 10px 0px #00000040;
  background-color: #fff;
  padding: 1rem;
  gap: 1rem;
  width: 100%;
  transition: ease-in-out 0.5s;
}

.quiz_data {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  width: 80%;
}

.quiz_heading {
  font-size: 24px;
  font-weight: 600;
  color: #3f2203;
}

.quiz_info {
  display: flex;
  align-items: left;
  align-items: start;
  gap: 1rem;
  font-size: 16x;
  font-weight: 400;
  color: #636363;
}

.quiz_content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: start;
  justify-content: flex-start;
}

.quiz_detail_container {
  display: flex;
  align-items:center;
  justify-content:center;
  padding: 4rem;
  flex-direction: column;
  color: #3F2203;
  font-size: 40px;
  font-weight: 700;
  gap: 2rem;
  text-align: center;
}

.connect_button{
  cursor: pointer;
  background: #F99C38;
  color: #000;
  font-size: 24px;
  border: none;
  padding: 1rem;
}

.quiz_button {
  background-color: #f8e9d5;
  color: #3f2203;
  font-weight: 700;
  font-size: 16px;
  max-width: 80px;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  text-align: center;
  cursor: pointer;
}

#blog {
  display: none;
}

.Tmon {
  min-height: 420px;
  margin-bottom: 10px;
  background-color: #f3f7f7 !important;
}

.blog-container {
  background-color: rgb(243, 247, 247);
  justify-content: space-evenly;
  padding-top: 30px;
  box-shadow: 0 2px 0 0 #ecf1f2;
  border-radius: 8px;
}

.counter {
  padding-left: 7px;
  padding-top: 10px;
  font-size: 24px;
}

#content {
  margin-top: 20px;
}

#content p {
  margin: 0px !important;
}

#draw .MuiDrawer-paper {
  width: 50% !important;
  background-color: rgb(243, 247, 247);
}

.mob-icon .MuiListItemText-root {
  text-align: left;
  padding-left: 5px;
}

.mob-icon {
  border-bottom: 2px solid #fff !important;
  border-radius: 0px !important;
}

.mob-menu {
  display: none;
}

.subHead .btn {
  background-color: none;
  color: #000;
  margin-left: 20px;
  font-weight: bold;
}

.blog-content {
  background-color: #fff;
  padding-top: 20px;
}

.ql-toolbar span,
.ql-toolbar svg {
  font-size: 18px !important;
}

.ql-container {
  min-height: 300px;
  font-size: 16px !important;
}

.blog-content span {
  padding: 10px;
  color: #6c6c6c;
  font-size: 18px;
  font-family: Montserrat;
}

.blog-content #content {
  padding: 15px;
  padding-bottom: 30px;
  color: #6c6c6c;
}

.blog-content h2 {
  line-height: 28px;
  margin: 0px 0 30px;
  padding-top: 5px;
  padding-left: 10px;
  color: #1c1c1c;
}

.post-title span {
  font-size: 18px;
  line-height: 28px;
  margin: -5px 0 30px;
  letter-spacing: 8px;
  padding-right: 20px;
  color: #1c1c1c;
}

.author-p {
  color: #6c6c6c;
}

.author-image {
  float: left;
  margin-right: 25px;
}

.author-image img {
  width: 150px;
  height: 150px;
}

.single-card {
  display: flex;
  flex-direction: row;
  box-shadow: none !important;
}

.chip-container {
  margin-top: -30px;
  margin-bottom: 25px;
}

.float {
  float: right;
}

.taken {
  display: inline-flex;
  padding: 5px;
  font-size: 16px;
}

.count {
  color: #F99C38;
  padding-right: 4px;
}

.count-icon {
  color: #f99c38 !important;
  margin-top: -3px !important;
}

.single-card img {
  border-radius: 16px;
  height: 165px;
}

.button-default {
  background-color: #F99C38 !important;
  color: #fff !important;
}

.take-quiz {
  color: #F99C38 !important;
  border-color: #F99C38 !important;
}

.take-quiz:hover {
  background-color: #F99C38 !important;
  color: #fff !important;
}

.card-div {
  width: 92% !important;
  margin-bottom: 10px;
}

.googleBtn {
  background-color: #dc4e41;
  width: 300px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  padding: 16px;
  align-items: center;
  font-weight: 600;
  border: none;
  margin-left: auto;
  margin-right: auto;
  color: white;
}

.fbBtn {
  background-color: #1877f2;
  width: 300px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  padding: 13px;
  color: white;
  font-weight: 600;
  align-items: center;
  border: none;
  margin-left: auto;
  margin-right: auto;
}

.googleBtn img {
  width: 20px;
  height: 20px;
  padding: 2px;
  margin: 0px 5px;
  vertical-align: middle;
}

.login {
  justify-content: center;
  align-content: center;
  height: 60vh;
  background: white;
  text-align: center;
}

.head {
  font-size: 28px;
}

.cards {
  padding-left: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.MuiIconButton-root span {
  font-size: 1rem !important;
}

.card {
  padding-bottom: 20px;
}

.newquiz__header {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #241218;
}

.overlay-text {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(90deg, #f97841, #eb3d7b);
  color: #000;
  font-size: 38px;
  font-weight: 700;
}

.overlay-subtext {
  color: #fff;
  /* font-weight: 500; */
  font-size: 21px;
}

.newquizbody_container {
  box-shadow: 0px 0px 5px 0px #00000040;
  padding: 4rem;
  display: flex;
  background-color: #fffdfa;
  gap: 1rem;
  flex-direction: column;
}

.newquizbody_left {
  gap: 1rem;
  display: flex;
}

.newbody_banner_left {
  display: flex;
  gap: 3rem;
  justify-content: center;
  align-items: center;
}

.newbody_banner_main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.newbody_banner_heading {
  font-size: 34px;
  font-weight: 550;
  color: #F99C38;
}

.newbody_details_section {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;
  justify-content: start;
  align-items: start;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #241218;
  color: #fff;
}

.newbody_details_heading {
  font-size: 32px;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(90deg, #f97841, #eb3d7b);
  color: #000;
  font-weight: 700;
}

.newbody_details_subheading {
  font-weight: bold;
  font-size: 18px;
}

.newbody_details_points {
  font-size: 16px;
  font-weight: 550;
  gap: 1rem;
}

.newbody_details_points li {
  padding-bottom: 15px; /* Adjust the value as needed */
}

.info_container {
  padding: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}

.info_sub_container {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.info_heading {
  font-weight: bold;
  text-align: center;
  font-size: 24px;
  background-color: #241218;
  padding: 1rem;
  border-radius: 5px;
}

.info_heading p {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(90deg, #f97841, #eb3d7b);
  color: #000;
}

.info_button {
  text-align: center;
  border-radius: 10px;
  border: none;
  width: 300px;
  font-weight: 550;
  font-size: 20px;
  padding: 1rem;
  color: #fff;
  background-color: #F99C38;
  cursor: pointer;
  transition: ease-in-out 0.5s;
}

.info_button:hover {
  transform: translateY(-4px);
  box-shadow: 3px 3px 5px #F99C38;
}

.profilecontainer {
  display: flex;
}

.profile_header {
  padding: 3rem 1rem;
  height: calc(100vh - calc(62.104px + 6rem));
  flex: 1;
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.profile_header_heading {
  font-weight: bold;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #000;
  text-align: center;
}

.profile_name {
  position: relative;
  width: 400px;
}

.profile_header_subheading {
  color: #252424d9;
}

.linkedinIcon {
  position: absolute;
  top: -12px;
  right: 0px;
}

.profilepagedescription {
  font-size: 18px;
  color: #000;
}

.profile_body {
  height: calc(100vh - 62.104px);
  margin-top: 0.5rem;
  flex: 3;
  background-color: #fff;
}

.profile_body_container {
  height: calc(100vh - calc(50px));
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fffdfa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scorecard_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 0 2rem;
}

.scorecard_data {
  text-align: center;
  height: 130px;
  width: 185px;
  border-radius: 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border: 2px solid #F99C38;
  cursor: pointer;
  transition: ease-in-out 0.5s; /* Add transition for smooth hover effect */
}

.scorecard_data:hover {
  transform: translateY(-4px);
  box-shadow: 3px 3px 5px #F99C38;
  /* border: 2px solid #F99C38; */
  /* background-color: #F99C38; */
  /* color: #fff; */
}

.scorecard_data1 {
  text-align: center;
  height: 130px;
  width: 185px;
  border-radius: 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border: 2px solid #F99C38;
  /* cursor: pointer; */
  transition: ease-in-out 0.5s; /* Add transition for smooth hover effect */
}

.scorecard_data1:hover {
  transform: translateY(-4px);
  box-shadow: 3px 3px 5px #F99C38;
  /* border: 2px solid #F99C38; */
  /* background-color: #F99C38; */
  /* color: #fff; */
}

.attempt_quiz button {
  transition: display 0.8s ease;
  display: none;
}

.take_quiz {
  border: none;
  background-color: #F99C38;
  color: #fff;
  padding: 5px 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 550;
  border-radius: 5px;
  cursor: pointer;
}

.scorecard_data1:hover .attempt_quiz button {
  display: block; /* Or use 'inline-block' depending on your layout needs */
}

.scorecard_data1:hover .scorecard_content .scorecard_heading {
  display: none;
}

.scorecard_heading {
  font-size: 17px;
  color: #6c6c6c;
}

.scorecard_content {
  font-weight: bold;
  font-size: 22px;
}

.my_dashboard_link {
  border: none;
  background-color: #F99C38;
  color: #fff;
  padding: 8px 10px;
  text-align: center;
  font-size: 18px;
  font-weight: 550;
  border-radius: 5px;
  cursor: pointer;
  width: 250px;
  transition: ease-in-out 0.5s;
}

.my_dashboard_link:hover {
  transform: translateY(-4px);
  box-shadow: 3px 3px 5px #F99C38;
  background-color: #b0343c;
}

.invaliduser_error {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 28px;
}

.footer_container {
  padding: 3rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f8e9d5;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  flex-direction: column;
  /* color: #fff; */
  font-weight: bold;
  text-align: center;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
}

.footer_desc {
  font-size: 18px;
  padding-bottom: 2rem;
  color: #494949;
  border-bottom: 2px solid #666565;
}

.footer_sub_desc {
  font-size: 15px;
  color: #606060;
}

@media screen and (max-width: 767px) {
  .card {
    padding-left: 10px;
    padding-right: 10px;
  }
  .profile_name {
    width: 250px;
  }
}

@media screen and (max-width: 567px) {
  .info_container {
    padding: 4rem 0rem;
    text-align: center;
  }

  .info_heading {
    font-size: 22px;
  }

  .info_sub_container {
    flex-direction: column;
  }

  .profile_name {
    width: auto;
  }

  .quiz_cards {
    padding: 3rem 1rem;
  }

  .profile_body_container {
    height: auto;
  }

  .profilecontainer {
    flex-direction: column;
    gap: 2rem;
  }
  .profile_header {
    flex: 0;
  }
  .profile_body {
    height: auto;
  }

  .scorecard_container {
    padding: 2rem 2rem;
  }

  .info_button {
    width: 250px;
    font-size: 17px;
  }
}

@media only screen and (max-width: 767px) {
  .fbBtn,
  .googleBtn {
    width: 261px;
  }
}

.name {
  padding-left: 20px;
}

.logout {
  padding-right: 20px;
}

.links {
  color: white;
  text-decoration: none;
}

.quiz {
  padding-top: 20px;
}

.admin-table {
  width: 100%;
  border: 2px solid #ddd;
  border-spacing: 0px;
  text-align: center;
}

.admin-table th {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.admin-table td {
  text-align: center;
  padding: 5px;
}

.admin-table th {
  background-color: darkgray;
}

.admin-table td,
.admin-table th {
  border: 1px solid black;
}

.admin-table tr:nth-child(even),
.drawer {
  background-color: #f2f2f2;
}

.clock {
  width: 110px;
  text-align: center;
  padding: 0px 15px 0px 15px;
  color: #000;
  border-radius: 50%;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 30px !important;
}

.questions {
  margin: auto !important;
}

.Test-Head {
  padding-bottom: 30px;
  text-align: center;
}

.names h3 {
  padding: 0px !important;
  margin: 0px !important;
}

.blog-img,
#content img {
  width: 100%;
  max-height: 400px;
}

.names p {
  margin: 0px !important;
  padding-top: 5px !important;
  padding-bottom: 10px !important;
}

.media img {
  border-radius: 50%;
  width: 120px;
  height: 120px;
}

.media2 {
  height: 0;
  padding-top: 56.25%;
  background-size: contain !important;
}

.expand {
  transform: rotate(0deg);
  margin-left: auto;
}

.chip {
  margin-right: 5px;
}

.detail {
  justify-content: space-between;
  padding-left: 20px;
}

.title {
  display: flex;
  justify-content: space-between;
}

.title h3 {
  margin-top: 5px !important;
}

.title h5 {
  color: rgba(0, 0, 0, 0.54) !important;
  font-weight: 400;
  margin-top: 7px !important;
}

.score {
  color: blue;
}

.login {
  background-image: url("https://recruitingmonk.com/wp-content/uploads/2020/03/Profile-back.png");
  background-repeat: no-repeat;
  background-position: center center;
}

.logo {
  width: 180px;
  height: 60px;
  float: left;
  padding-left: 25px;
}

.sidebar {
  background-color: rgb(243, 247, 247);
  border-radius: 16px;
  padding: 25px;
}

.sidebar h2 {
  color: #F99C38;
  display: flex;
}

.sidebar .MuiCardContent-root {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.side-card {
  cursor: pointer;
}

.card {
  max-height: 85vh;
  overflow: auto;
}

.card::-webkit-scrollbar {
  width: 10px;
  scroll-margin-top: 20px;
}

/* Track */
.card::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
.card::-webkit-scrollbar-thumb {
  background: #b0343c;
  border-radius: 5px;
}

/* Handle on hover */
.card::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

@media only screen and (max-width: 767px) {
  .login {
    height: 60vh;
  }

  .cards {
    padding-left: 0px;
  }

  .sidebar {
    border-radius: 12px;
    padding: 12px;
    position: relative;
  }

  .logo {
    padding-left: 5px;
  }

  .logout {
    padding-right: 5px;
  }

  .name {
    padding-left: 5px;
  }

  /* ssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss */

  .overlay-text {
    font-size: 28px;
  }

  .overlay-subtext {
    font-size: 18px;
  }

  .newquizbody_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .newquizbody_left {
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }

  .newbody_banner_main {
    display: flex;
    gap: 1rem;
  }

  .newbody_banner_heading {
    font-size: 34px;
    font-weight: 550;
  }

  .newbody_banner_left {
    flex-direction: column;
  }

  .newbody_banner {
    flex-direction: column;
    gap: 2rem;
  }

  .newbody_banner_image {
    width: 300px;
    border-radius: 10px;
  }

  .newbody_details_section {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 2rem;
  }

  .newbody_details_heading {
    font-weight: bold;
    font-size: 24px;
  }

  .newbody_details_subheading {
    font-weight: bold;
    font-size: 18px;
  }

  .newbody_details_points {
    font-size: 16px;
    font-weight: 550;
    gap: 1rem;
  }

  .newbody_details_points li {
    padding-bottom: 15px; /* Adjust the value as needed */
  }
}

.MuiFormHelperText-root {
  color: red !important;
}

.more {
  margin-top: 20px;
  margin-bottom: 50px;
}

.top {
  justify-content: space-between;
}

.canvasjs-chart-credit {
  display: none !important;
}

.canvasjs-chart-toolbar {
  display: none;
}

.root {
  font-family: "Montserrat";
  margin-left: 50px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.2) !important;
}

.linkedin {
  color: #0e76a8 !important;
  float: right;
  right: 50%;
}

.td-name .MuiIconButton-root {
  padding: 0px !important;
}

.root .td-name {
  text-align: left;
  padding: 15px;
}

.root td {
  text-align: center;
  padding: 5px;
}

.root tr {
  border-bottom: 1px solid #e7eeef;
}

.cat-chooser .MuiInput-underline:before,
.cat-chooser .MuiInput-underline:after {
  border-bottom: none !important;
}

.cat-chooser select {
  padding-left: 30px;
}

.question-cat-container .cat-chooser-select {
  background: white !important;
  padding: 0px !important;
}

.cat-chooser {
  background: #ffffff;
  padding: 20px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  display: inline-flex;
}

.leader-quiz {
  margin-top: 30px;
  margin-bottom: 40px;
  margin-left: 50px;
  width: auto !important;
  justify-content: space-between;
}

.leaders {
  width: 97%;
}

.cat-chooser .MuiNativeSelect-icon {
  display: none !important;
}

.filter-label {
  align-self: center;
  color: rgba(0, 0, 0, 0.54);
}

.MuiNativeSelect-select:focus {
  background-color: white !important;
}

.side-bar {
  padding-left: 10px;
}

@media only screen and (max-width: 990px) {
  .card {
    max-height: 600px;
    overflow: auto;
  }

  .card h1 {
    font-size: 24px !important;
  }

  .Tmon {
    min-height: 200px;
  }

  .mob-menu {
    display: block;
    color: #F99C38;
    width: 50px;
  }

  #mob {
    justify-content: space-between;
  }

  .mob-menu .icons {
    color: #F99C38 !important;
  }

  .icons {
    color: #F99C38 !important;
  }

  .subHead {
    display: none;
  }

  .blog-content #content {
    padding: 10px;
  }

  .chip-container {
    margin-top: -20px;
  }

  .side-bar {
    padding-left: 0px;
  }

  .leaders {
    width: 100%;
  }

  .MuiTablePagination-actions,
  .MuiTablePagination-selectRoot {
    margin: 0px !important;
  }

  .leader-quiz {
    margin: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .root .td-name {
    text-align: left;
    padding: 7px;
  }

  .MuiNativeSelect-select:focus {
    background-color: transparent !important;
  }

  .root td {
    padding: 2px;
  }

  .cat-chooser select {
    padding-left: 2px;
  }

  .cat-chooser {
    padding: 2px;
    background-color: transparent;
    box-shadow: none;
  }

  .leaders label {
    display: none !important;
  }

  .root {
    margin: 0px;
  }

  .linkedin {
    float: right;
    right: 0%;
  }

  .single-card {
    display: block;
    box-shadow: none !important;
  }

  .card-div {
    width: 100% !important;
  }

  .detail {
    padding-left: 0px;
  }
}

/* Blog Css */

.Blog {
  border: none;
  line-height: 20px;
  height: auto;
  z-index: 1;
  padding-bottom: 20px;
}

.Blog .bpHead {
  text-align: center;
}

.Blog .body {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  cursor: pointer;
}

.Blog .body::-webkit-scrollbar {
  display: none;
}
.Blog .body::-moz-scrollbar {
  display: none;
}

.Blog .body .body1 {
  margin: 10px;
  width: 280px;
  height: 300px;
  padding: 5px;
  line-height: normal;
  border-radius: 7px;
  position: relative;
  z-index: 5;
}

.body1 .bgTitle {
  width: 260px;
  color: #fff;
  z-index: 10;
}

.bgTitle p {
  position: absolute;
  bottom: 0px;
}

.Blog .body {
  overflow: scroll;
}

.Blog .body::-webkit-scrollbar {
  display: block;
}
.Blog .body::-moz-scrollbar {
  display: block;
}

.Blog .body::-webkit-scrollbar:horizontal {
  height: 11px;
}

.Blog .body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  /* background-color: rgba(0, 0, 0, .5); */
  background-color: #b0343c;
}

@media only screen and (min-width: 800px) {
  .Blog {
    padding: 10px;
    margin-top: 40px;
    padding-bottom: 30px;
  }
}


@media only screen and (max-width: 920px) {
  .headerContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 4rem;
    background-color: #fffdfa;
    gap: 3rem;
  }
  
  .header_content {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 2rem;
    color: #3f2203;
  }
  
  .header_title {
    line-height: 1.2;
    text-align: center;
    font-size: 64px;
    width: 100%;
    font-weight: bold;
  }
  
  .header_subtitle {
    font-size: 24px;
    text-align: center;
    font-weight: 400;
    width: 100%;
  }
  
}

@media only screen and (max-width: 576px){

  .headerContainer {
    padding: 4rem 2rem;
  }

  .newquizbody_container {
    padding: 2rem;
  }

  .header_title {
    font-size: 40px;
  }
  
  .header_subtitle {
    font-size: 20px;
  }

  .header_image{
    width: 300px;
  }
  
  .infocontent {
    width: 100%;
  }
  
  .quiz_cards {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    width: 90%;
  }
  
  .quiz_card {
    flex-direction: column;
  }
  
  .quiz_data {
    text-align: center;
    flex-direction: column;
    width: 100%;
  }
  
  .quiz_content {
    align-items: center;
    justify-content: center;
  }
  
  .connect_button{
    background: #F99C38;
    color: #000;
    font-size: 18px;
    border: none;
    padding: 1rem;
  }
  
  .quiz_button {
    margin-top: 1rem;
  }

  .quiz_detail_container {
    font-size: 24px;
    text-align: center;
  }
}